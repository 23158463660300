<template>
   <div class="mainform">
        <!-- 手动录入发票 -->
        <div class="form">
                <el-form  class="content bascform" ref="form" :model="form"   label-width="120px">
                    <div class="titleinfo" >
                        <el-form-item label="税务发票信息" ></el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="发票代码" prop="invoiceCode" :rules="{ required: true, message: '请输入发票代码', trigger: 'blur' }">
                            <el-input v-model="form.invoiceCode" oninput="if(value.length>12)value=value.slice(0,12)"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="含税开票金额" prop="totalAmount" :rules="{ required: true, message: '请输入含税开票金额', trigger: 'blur' }">
                            <el-input ref="totalAmount" @blur='totalAmountChange' v-model="form.totalAmount" readonly></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="发票号" prop="invoiceNo" :rules="{ required: true, message: '请输入发票号', trigger: 'blur' }">
                            <el-input v-model="form.invoiceNo" oninput="if(value.length>12)value=value.slice(0,12)"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="未税开票金额" prop="invoiceAmount" :rules="{ required: true, message: '请输入未税开票金额', trigger: 'blur' }">
                            <el-input ref="invoiceAmount" @blur='invoiceAmountChange' v-model="form.invoiceAmount"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="开票日期" prop="invoiceDate" :rules="{ required: true, message: '请选择开票日期', trigger: 'blur' }">
                            <el-date-picker v-model="form.invoiceDate" type="date" @change="timeForm" placeholder="选择日期" > </el-date-picker>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="开票税额" prop="taxAmount" :rules="{ required: true, message: '请输入开票税额', trigger: 'blur' }">
                            <el-input ref="taxAmount" @blur='taxAmountChange' v-model="form.taxAmount"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col col4">
                        <el-form-item label="币种" prop="currency" >
                          <el-select v-model="form.currency">
                            <el-option :key="index" v-for="(itme,index) in currencyOption" :label="itme.label" :value="itme.label">
                            </el-option>
                          </el-select>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="form.remark"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <div style="color:#FF8200">说明:多张纸票,需要单张录入,不允许合并</div>
        </div>
    </div>
</template>

<script>
import { currencyOption } from './manuallyData'
import dayjs from 'dayjs'
export default {
  name: 'Manuallydialog',
  props: ['formData'],
  data () {
    return {
      form: this.formData,
      currencyOption: currencyOption
    }
  },
  created () {
    this.form.currency = 'RMB'
  },
  methods: {
    totalAmountChange () {
      const redInkMark = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.invoiceInfodata.redInkMark
      if (redInkMark !== 'X' && parseFloat(this.form.totalAmount) < 0) {
        this.$message({
          message: '非红字发票含税开票金额不可为负数！',
          type: 'error'
        })
        this.$refs.totalAmount.focus()
      }
      if (redInkMark === 'X' && parseFloat(this.form.totalAmount) > 0) {
        this.$message({
          message: '红字发票含税开票金额不可为正数！',
          type: 'error'
        })
        this.$refs.totalAmount.focus()
      }
    },
    invoiceAmountChange () {
      if (this.form.taxAmount !== '' && this.form.taxAmount !== null && this.form.invoiceAmount !== '' && this.form.taxAmount !== null && parseFloat(this.form.invoiceAmount) > 0 && parseFloat(this.form.taxAmount) > parseFloat(this.form.invoiceAmount)) {
        this.form.taxAmount = ''
        this.$message({
          message: '未税开票金额不得小于开票税额！',
          type: 'error'
        })
      } else {
        this.form.totalAmount = (Math.round((parseFloat(this.form.invoiceAmount) + parseFloat(this.form.taxAmount)) * 100) / 100).toFixed(2)
      }
      const redInkMark = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.invoiceInfodata.redInkMark
      if (redInkMark !== 'X' && parseFloat(this.form.invoiceAmount) < 0) {
        this.$message({
          message: '非红字发票未税开票金额不可为负数！',
          type: 'error'
        })
        this.$refs.invoiceAmount.focus()
      }
      if (redInkMark === 'X' && parseFloat(this.form.invoiceAmount) > 0) {
        this.$message({
          message: '红字发票未税开票金额不可为正数！',
          type: 'error'
        })
        this.$refs.invoiceAmount.focus()
      }
    },
    taxAmountChange () {
      if (this.form.taxAmount !== '' && this.form.taxAmount !== null && this.form.invoiceAmount !== '' && this.form.taxAmount !== null && parseFloat(this.form.invoiceAmount) > 0 && parseFloat(this.form.taxAmount) > parseFloat(this.form.invoiceAmount)) {
        this.form.taxAmount = ''
        this.$message({
          message: '开票税额不得大于未税开票金额！',
          type: 'error'
        })
      } else {
        this.form.totalAmount = (Math.round((parseFloat(this.form.invoiceAmount) + parseFloat(this.form.taxAmount)) * 100) / 100).toFixed(2)
      }
      const redInkMark = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.invoiceInfodata.redInkMark
      if (redInkMark !== 'X' && parseFloat(this.form.taxAmount) < 0) {
        this.$message({
          message: '非红字发票开票税额不可为负数！',
          type: 'error'
        })
        this.$refs.taxAmount.focus()
      }
      if (redInkMark === 'X' && parseFloat(this.form.taxAmount) > 0) {
        this.$message({
          message: '红字发票开票税额不可为正数！',
          type: 'error'
        })
        this.$refs.taxAmount.focus()
      }
    },
    // 选择合同生效日期转换格式
    timeForm () {
      if (this.form.invoiceDate) {
        this.form.invoiceDate = dayjs(this.form.invoiceDate).format('YYYYMMDD')
      } else {
        this.form.invoiceDate = ''
      }
    }
  }

}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
  .titleinfo{
      margin: 10px;
  }
</style>
