export var formData = [
  {
    invoiceCode: '',
    invoiceNo: '',
    totalAmount: '',
    invoiceAmount: '',
    taxAmount: '',
    invoiceDate: '',
    currency: '',
    remark: ''
  }
]
export var currencyOption = [
  { label: 'RMB', value: '1' },
  { label: 'USD', value: '2' }
]
